<template>
  <div class="account" style="padding-bottom: 100px; background: #fff">
    <top-fixed></top-fixed>
    <div style="margin-top: 0.68rem">
      <div
        class="huodongBg"
        v-for="(item, index) in activityData"
        @click="joinActivitys(item)"
        :key="index"
        style="border: 1px solid transparent; background-size: 7rem 2.07rem"
      >
        <div style="display: flex; justify-content: space-between">
          <div style="margin-top: 0.3rem">
            <div style="font-size: 15px; font-weight: bold">
              {{ item.act_name }}
            </div>
            <div style="margin: 0.4rem 0; font-size: 12px; color: #978f8f">
              {{ item.end_time }}
            </div>
          </div>

          <img style="width: 2rem" src="../assets/img2/activi.png" />
        </div>
        <div
          style="
            margin-top: 0.4rem;
            display: flex;
            justify-content: space-between;
            background: #f4f4f4;
            border: 0.5px solid #dddddd;
            border-radius: 9px;
            box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.08) inset;
            padding: 0.6rem;
          "
        >
          <div style="font-size: 0.6rem; font-weight: bold; color: #ff381d">
            {{ item.status_name }}
          </div>
          <div style="font-size: 0.5rem; color: #000000; margin-right: 0.4rem">
            {{ $t("hj21") }}{{ item.awards }}DETH >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import TopFixed from "../components/TopFixed.vue";
import { activity, joinActivity } from '../http/api'
export default {
  name: "AccountView",
  data () {
    return {
      activityData: ""
    };
  },
  components: { TopFixed },
  methods: {
    dataInit () {
      activity().then((res) => {
        this.activityData = res.data
      })
    },
    joinActivitys (item) {
      if (item.status == '0') {
        joinActivity({ activity_id: item.id }).then((res) => {
          this.$router.push({
            path: "/activitDea",
            query: {
              id: item.id
            }
          })
        })
      } else {
        this.$router.push({
          path: "/activitDea",
          query: {
            id: item.id
          }
        })
      }
    }
  },
  mounted () {
  },
  created () {
    this.dataInit()
  }
};
</script>
  <style  scoped lang="scss">
.huodongBg {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 0.6rem 1rem;
  margin-bottom: 0.8rem;
  margin: 0.8rem;
}
</style>